import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { datoApi } from '@hultafors/hellberg/api';
import {
  GlobalFields,
  PageNotFoundFragment,
  PageProps,
} from '@hultafors/hellberg/types';

const PageNotFoundContent = dynamic(() =>
  import('@hultafors/hellberg/components').then(
    (mod) => mod.PageNotFoundContent,
  ),
);

export interface PageNotFoundProps extends PageProps {
  content: PageNotFoundFragment;
}
export const getStaticProps: GetStaticProps<PageNotFoundProps> = async ({
  locale,
}) => {
  try {
    const dato = datoApi(locale);

    const {
      data: { pageNotFound: content, ...globalFields },
    } = await dato.getPageNotFound();
    const props: PageNotFoundProps = {
      ...(globalFields as GlobalFields),
      content,
    };
    return { props };
  } catch (error) {
    console.error(error);
    return {
      props: {} as PageNotFoundProps,
    };
  }
};

const Custom404: NextPage<PageNotFoundProps> = ({
  content,
  ...globalFields
}) => {
  if (!content) {
    return null;
  }
  return <PageNotFoundContent content={content} {...globalFields} />;
};

export default Custom404;
